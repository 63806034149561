import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { getSelectedUrlParam } from '/src/functions/paramTools'

const BackBreadcrumb = loadable(() => import('/src/components/Structure/Breadcrumb/BackBreadcrumb'))
const Breadcrumb = loadable(() => import('/src/components/Structure/Breadcrumb/Breadcrumb'))
const SearchHeader = loadable(() => import('/src/components/Search/SearchHeader'))
const SearchResults = loadable(() => import('/src/components/Search/SearchResults/SearchResults'))

const Search = ({ postTypeInfo, breadcrumb, headingLevel, searchData }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [prevSearch, setPrevSearch] = useState('')

    const resetSearch = () => {
        setSearchTerm('')
        setPrevSearch('')
    }

    useEffect(() => {
        // Check if there is a 'search' parameter attached to the URL and set the search term
        getSelectedUrlParam('search', setPrevSearch)
        setSearchTerm(prevSearch)
    }, [prevSearch])

    return (
        <>
            <SearchHeader
                setSearchTerm={setSearchTerm}
                prevSearch={prevSearch}
                postTypeInfo={postTypeInfo}
                headingLevel={headingLevel}
            />

            {breadcrumb.type === 'back' && searchTerm !== '' ? (
                <BackBreadcrumb
                    size={'narrow'}
                    title={breadcrumb.backTitle}
                    uri={breadcrumb.uri}
                    resetFunction={resetSearch}
                />
            ) : breadcrumb.type === 'standard' ? (
                <Breadcrumb size={'narrow'} pageTitle={breadcrumb.pageTitle} pageParent={breadcrumb.pageParent} />
            ) : null}

            {searchTerm && (
                <SearchResults
                    postType={postTypeInfo.postType}
                    postTypeTitle={postTypeInfo.title}
                    searchData={searchData}
                    searchTerm={searchTerm}
                />
            )}
        </>
    )
}

Search.propTypes = {
    postTypeInfo: PropTypes.shape({
        postType: PropTypes.string,
        title: PropTypes.string,
        uri: PropTypes.string,
        hero: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string
        })
    }),
    breadcrumb: PropTypes.shape({
        type: PropTypes.oneOf(['standard', 'back']),
        pageTitle: PropTypes.string,
        pageParent: PropTypes.shape({
            node: PropTypes.shape({
                title: PropTypes.string.isRequired,
                uri: PropTypes.string.isRequired,
                wpParent: PropTypes.shape({
                    node: PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        uri: PropTypes.string.isRequired
                    })
                })
            })
        })
    }),
    headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']),
    /**
     * Search data object
     */
    searchData: PropTypes.object
}

Search.defaultProps = {
    postTypeInfo: {
        postType: 'post',
        title: 'Post Type Title',
        uri: '/page-uri/',
        hero: {
            eyebrow: 'Hero eyebrow text goes here',
            title: 'Hero title text goes here'
        }
    },
    breadcrumb: {
        type: 'standard',
        pageTitle: 'Page Title',
        pageParent: {
            node: {
                title: 'Page Title',
                uri: '/page-url/'
            }
        }
    },
    headingLevel: 'h1'
}

export default Search
